<template>
  <CRow class="">
    <CCol col>
      <CCard>
          <CCardHeader>
              <div class="row">
                  <div class="col-md-6">
                      <strong>Create Page</strong>
                  </div>
              </div>
          </CCardHeader>
          <CCardBody>
              <div class="row">
                  <b-col lg="12" sm="12">
                    <b-overlay :show="loading">
                          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                  <div class="row">
                                      <div class="col-md-4">
                                        <ValidationProvider name="Page Type" vid="page_type" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="formData"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            Page Type <span class="text-danger">*</span>
                                            </template>
                                            <Select2
                                            :options="types"
                                                id="page_type"
                                                rows="6"
                                                v-model="formData.page_type"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></Select2>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </div>
                                      <div class="col-md-4">
                                          <ValidationProvider name="Select Section" vid="section_id" rules="">
                                              <b-form-group
                                              class="row"
                                              label-cols-sm="12"
                                              label-for="formData"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              Select Section  <span class="text-danger">*</span>
                                              </template>
                                              <Select2
                                              :options="sectionList"
                                                  id="section_id"
                                                  rows="6"
                                                  v-model="formData.section_id"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></Select2>
                                              <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </div>
                                      <div class="col-md-4">
                                          <ValidationProvider name="Menu Name" vid="menu_name" rules="">
                                              <b-form-group
                                              class="row"
                                              label-cols-sm="12"
                                              label-for="formData"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  Menu Name <span class="text-danger">*</span>
                                              </template>
                                              <b-form-input
                                                  type="text"
                                                  id="menu_name"
                                                  v-model="formData.menu_name"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                              <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </div>
                                      <div class="col-md-4">
                                          <ValidationProvider name="Title" vid="title" rules="">
                                              <b-form-group
                                              class="row"
                                              label-cols-sm="12"
                                              label-for="formData"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  Title <span class="text-danger">*</span>
                                              </template>
                                              <b-form-input
                                                  type="text"
                                                  id="title"
                                                  v-model="formData.title"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                              <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </div>
                                      <div class="col-md-4">
                                          <ValidationProvider name="Select Ads" vid="ads_id" rules="">
                                              <b-form-group
                                              class="row"
                                              label-cols-sm="12"
                                              label-for="formData"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              Select Ads
                                              </template>
                                              <Select2
                                              :options="adsList"
                                                  id="ads_id"
                                                  rows="6"
                                                  v-model="formData.ads_id"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></Select2>
                                              <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </div>
                                      <div class="col-md-4">
                                        <ValidationProvider name="Icon" vid="icon_file" rules="">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="formData"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                              Icon<span class="text-danger">*</span>
                                            </template>
                                            <b-form-file
                                                id="icon_file"
                                                rows="6"
                                                v-model="formData.icon_file"
                                                @change="handleImg"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </div>
                                      <div class="col-md-12">
                                          <ValidationProvider name="Description" vid="description" rules="">
                                              <b-form-group
                                              class="row"
                                              label-cols-sm="12"
                                              label-for="formData"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  Description <span class="text-danger">*</span>
                                              </template>
                                              <VueEditor 
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  v-model="formData.description"
                                              >
                                              </VueEditor>
                                              <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </div>
                                      <div class="col-md-6">
                                          <ValidationProvider name="Sort Oder" vid="sort" rules="required">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                Sort <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="sort"
                                                    rows="6"
                                                    v-model="formData.sort"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                      </div>
                                      <div class="col-md-6">
                                          <ValidationProvider name="Url" vid="url" rules="">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                Url
                                                </template>
                                                <b-form-input
                                                    id="url"
                                                    rows="6"
                                                    v-model="formData.url"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                      </div>
                                      <div class="col-md-12 text-right">
                                          <b-button type="submit" variant="primary" class="mr-2 btn-sm">Save</b-button>
                                          <b-button type="button" @click="()=>{$router.push('/pages')}" variant="danger" class="mr-2 btn-sm">Cancel</b-button>
                                      </div>
                                  </div>
                              </b-form>
                          </ValidationObserver>
                      </b-overlay>
                  </b-col>
                  <div v-if="id" class="col-md-12">
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                          <b-form  @submit.prevent="handleSubmit(saveDetail)" @reset.prevent="reset" >
                              <div class="row">
                                  <div class="col-md-4">
                                      <ValidationProvider name="Name" vid="name" rules="">
                                          <b-form-group
                                          class="row"
                                          label-cols-sm="12"
                                          label-for="detailForm"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              Name <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                              type="text"
                                              id="name"
                                              v-model="detailForm.name"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </div>
                                  <div class="col-md-4">
                                      <ValidationProvider name="Logo" vid="file" rules="">
                                          <b-form-group
                                          class="row"
                                          label-cols-sm="12"
                                          label-for="detailForm"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              File/Image <span class="text-danger">*</span>
                                          </template>
                                          <b-form-file
                                              type="text"
                                              id="file"
                                              @change="setFile"
                                              v-model="detailForm.file"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-file>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </div>
                                  <div class="col-md-2">
                                      <b-button type="submit" variant="primary" style="margin-top:37px" class="mr-2">{{ detailForm.id ? 'Update' : 'Save' }}</b-button>
                                  </div>
                              </div>
                          </b-form>
                      </ValidationObserver>
                  </div>
                  <div  v-if="id" class="col-md-12">
                      <table class="table table-sm table-bordered" style="width: 100% !important">
                          <tr>
                              <th>Sl No</th>
                              <th>Type</th>
                              <th>Title</th>
                              <th>File</th>
                              <th class="text-center">Action</th>
                          </tr>
                          <tr v-for="(file, index) in formData.attachments" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td>{{ getFileExtension(file.path) }}</td>
                              <td>{{ file.name }}</td>
                              <td><a  :href="file.path | filePath" class="btn btn-info btn-sm text-light" download>File</a></td>
                              <td class='text-center'>
                                  <b-button @click="setEdit(file)" type="button" variant="primary" class="mr-2 btn-sm">Edit</b-button>
                                  <b-button @click="removeDetail(file.id)" type="button" variant="danger" class="mr-2  btn-sm">x</b-button>
                              </td>
                          </tr>
                      </table>
                  </div>
              </div>
          </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
import { VueEditor } from "vue2-editor";
import { PAGE, PAGE_TYPE } from '../../Utils/Constant';

export default {
components: {
  ValidationObserver,
  ValidationProvider,
  VueEditor
},
data () {
  return {
    saveBtnName: "Update",
    formData: {
      'page_type': [],
      'icon_file': [],
      'title': null,
      'description': null,
      'ads_id': null,
    },
    detailForm: {
      'id': null,
      'file_type': 1,
      'file': [],
      'title': null
    },
    types: PAGE_TYPE
  }
},
computed: {
    id() {
        return this.$route.query.id
    },
    loading () {
    return this.$store.state.static.loading
    },
    sectionList () {
        const data = this.$store.state.commonObj.sectionList.filter(item => item.type_id == PAGE)
        data.unshift({id: 0, text: 'Select'})
        return data
    },
  adsList () {
      return this.$store.state.commonObj.adsList
  }
},
methods: {
  setFile (event) {
      this.formData.logo_file = event.target.files[0]
  },
  setEdit (item) {
      this.detailForm = JSON.parse(JSON.stringify(item)) 
  },
  getData () {
      this.$store.dispatch('mutedLoad', { loading: true})
      RestApi.getData(baseUrl, 'api/pages/show', {id: this.id}).then(response => {
          if (response.success) {
              this.formData = response.data
              this.formData.maintanance_mode = response.data.maintanance_mode == '1' ? true : false
          }
          this.$store.dispatch('mutedLoad', { loading: false })
      })
      this.$store.dispatch('mutedLoad', { loading: true})
  },
  async register () {
      this.$store.dispatch('mutedLoad', { loading: true })
      let result = null

      let formData = new FormData()
      Object.keys(this.formData).map(key => {
          formData.append(key, this.formData[key])
      });
      if (this.id) {
          formData.append('_method', 'put')
          result = await RestApi.postData(baseUrl, `${'api/pages/update'}/${this.id}`, formData)
      } else {
          result = await RestApi.postData(baseUrl,'api/pages/store', formData)
      }
      this.$store.dispatch('mutedLoad', { loading: false })

      if (result.success) {
    iziToast.success({
      title: 'Success',
      message: result.message
    })

          setTimeout(()=> {
              this.$router.push('/pages/create?id='+result.data.id)
          }, 1000)
      } else {
          this.$refs.form.setErrors(result.errors)
      }
  },
  async saveDetail () {
      this.$store.dispatch('mutedLoad', { loading: true })
      let result = null

      let formData = new FormData()
      Object.keys(this.detailForm).map(key => {
          formData.append(key, this.detailForm[key])
      });
          formData.append('_method', 'put')
          result = await RestApi.postData(baseUrl, `${'api/pages/detail/store'}/${this.id}`, formData)
          this.$store.dispatch('mutedLoad', { loading: false })
          this.getData()
      if (result.success) {
    iziToast.success({
      title: 'Success',
      message: result.message
    })
      } else {
          this.$refs.form.setErrors(result.errors)
      }
  },
  async removeDetail (id) {
      this.$store.dispatch('mutedLoad', { loading: true })
      let result = null
      result = await RestApi.deleteData(baseUrl, `${'api/pages/detail/delete'}/${id}`)
      this.$store.dispatch('mutedLoad', { loading: false })
      
      this.getData()
      if (result.success) {
    iziToast.success({
      title: 'Success',
      message: result.message
    })
      } else {
          this.$refs.form.setErrors(result.errors)
      }
  },
  getFileExtension(filePath) {
      return filePath.split('.').pop();
  }
},
mounted () {    
  this.$store.dispatch('mutedLoad', { loading: false })
  if (this.id) {
      this.getData()
  }
}
}
</script>
<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";
/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
